import React, { useEffect, useState } from 'react';
import './form.css';
import Dropdown from 'react-dropdown';
import axios from 'axios';


function Form() {
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [cedula, setCedula] = useState('');
    const [pais, setPais] = useState('');
    //const [apellidos, setPassword] = useState('');
    const [resultado, setResultado] = useState(0);


    const options= ['','Colombia','Costa Rica','Estados Unidos','Panama','Guatemala','El Salvador','España','Honduras','Nicaragua'];
    //const options= [{key:'Costa Rica',value:1},{key:'Estados Unidos',value:1},{key:'Panama',value:1},{key:'Colombia',value:1},{key:'Guatemala',value:1},{key:'El Salvador',value:1},{key:'España',value:1},{key:'Honduras',value:1},{key:'Nicaragua',value:1}];

    const handlePais = (e) => {
            setPais(e.target.value);
    };

    const validateForm = () => {

    };

    const sendEmail = () =>  {
        let postData = {
            Email:email
          };

        const requestOptions = {
            method: 'POST',
            mode: 'no-cors',
            headers: { 'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST,OPTIONS',
                'Access-Control-Allow-Credentials': false,
                'x-api-key':'6JjzYduKK62eCXneIpCOnadGALpYUQkQ6i2uL7Gf'
             },
            body: JSON.stringify(postData)
        };

        fetch('https://j09yru2dbl.execute-api.us-east-1.amazonaws.com/Stage/SendEmail/', requestOptions)
        .then(response => response.json())
        .catch(error => {console.log('There was an error',error)});
    };

    const onSubmit = (event) => {
        // event.preventDefault()
        // let inputUsername = event.target.elements.username.value
        // let inputPassword = event.target.elements.password.value
        // setUsername(inputUsername)
        // setPassword(inputPassword)
        validateForm();


        let postData = {
            Nombre: nombre,
            Apellido: apellidos,
            Email:email,
            Telefono:telefono,
            Cedula:cedula,
            Pais: pais
          };

        const requestOptions = {
            method: 'POST',
            mode: 'no-cors',
            headers: { 'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST,OPTIONS',
                'Access-Control-Allow-Credentials': false,
                'x-api-key':'6JjzYduKK62eCXneIpCOnadGALpYUQkQ6i2uL7Gf'
             },
            body: JSON.stringify(postData)
        };

        fetch('https://j09yru2dbl.execute-api.us-east-1.amazonaws.com/Stage/register/', requestOptions)
        .then(function(responses) {
            //console.log("Result: ", JSON.stringify(responses));
            setResultado(1);
            return responses;
        })
        .then((r) => {
            console.log(r.status);
        })
        .catch(error => {
            console.log('There was an error', error);
            setResultado(2);
        });

        
        // const xhr = new XMLHttpRequest();
        // xhr.withCredentials = false;

        // xhr.open('GET', 'https://ooyryf0y2d.execute-api.us-west-1.amazonaws.com/Stage/Task/ListStatus');
        // xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        // xhr.setRequestHeader('Access-Control-Allow-Headers', '*');
        // xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

        // var data = new FormData();
        // data.append('Nombre','Jon');
        // data.append('Apellido','Jon');
        // data.append('Email','Jon');
        // data.append('Cedula','Jon');

        // xhr.onload = function () {
        //     if (xhr.status === 200) {
        //         console.log('Success');
        //     }
        // };
        // //xhr.send(JSON.stringify(postData));
        // xhr.send();

        // const options = {
        //     method: 'POST',
        //     url: 'https://j09yru2dbl.execute-api.us-east-1.amazonaws.com/Stage/register/',
        //     headers: {
        //         'Access-Control-Allow-Credentials': false,
        //         'Access-Control-Allow-Origin': '*',
        //         'Access-Control-Allow-Methods': 'POST,OPTIONS',
        //         'Access-Control-Allow-Headers': 'Content-Type',
        //         'Content-Type': 'application/json',
        //         'x-api-key':'6JjzYduKK62eCXneIpCOnadGALpYUQkQ6i2uL7Gf',
        //     },
        //     data: JSON.stringify({Nombre: 'Nom', Email: 'password'}),
        //   };

        // const response = axios(options).catch((err) => err);
        // if (response.data) {
        //     alert(response.data.error);
        // } else {
        //     console.log('Yes');
        // }
        // const headers = {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Credentials': false,
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'POST,OPTIONS',
        //     'x-api-key':'6JjzYduKK62eCXneIpCOnadGALpYUQkQ6i2uL7Gf',
        //   }
        // axios.post('https://j09yru2dbl.execute-api.us-east-1.amazonaws.com/Stage/register/', JSON.stringify({ Nombre: 'kk', Apellido: '', Email: 'test' }), {headers})
        //     .then(function (response) {
        //         console.log(response);
        //     })
        //     .catch(error => {
        //         console.error(error);
        //     })
    }


    return (
        <div className='formContainer'>

            <div className='formRow'>
                {
                    resultado !== 0 ? (resultado == 1 ? <label className='SuccessReg' >Registro Exitoso</label> : <label className='FailReg'>Error al procesar el registro.</label>) : null 
                }
            </div>
            <div className='formRow'>
                <div style={{ display: 'inline-block', width: '120px' }}>
                    <label className='Label'> Nombre: </label>
                </div>
                <div style={{ display: 'inline-block' }}>
                    <input type="text"
                        onChange={e => setNombre(e.target.value)}/>
                </div>
            </div>
            <div className='formRow'>
                <div style={{ display: 'inline-block', width: '120px' }}>
                    <label className='Label'>Apellidos:</label>
                </div>
                <div style={{ display: 'inline-block' }}>
                <input type="text" 
                    onChange={e => setApellidos(e.target.value)}/>
                </div>
            </div>

            <div className='formRow'>
                <div style={{ display: 'inline-block', width: '120px' }}>
                    <label className='Label'>Telefono:</label>
                </div>
                <input type="text" onChange={e => setTelefono(e.target.value)}/>
            </div>

            <div className='formRow'>
                <div style={{ display: 'inline-block', width: '120px' }}>
                    <label className='Label'>Email:</label>
                </div>
                <input type="text" onChange={e => setEmail(e.target.value)}/>
            </div>
            <div className='formRow'>
                <div style={{ display: 'inline-block', width: '120px' }}>
                    <label className='Label'>Pais de Origen:</label>
                </div>
                <select className='ddPais'  options={options} onChange={handlePais}>
                    {options.map((item) => (
                        <option>{item}</option>
                    ))}
                </select>
                {/* <Dropdown options={options} onChange={handlePais} placeholder="Select an option" className='ddPais'/> */}
            </div>
            <div className='formRow'>
                <div style={{ display: 'inline-block', width: '120px' }}>
                    <label className='Label'>Cédula / Pasaporte:</label>
                </div>
                <input type="text" onChange={e => setCedula(e.target.value)}/>
            </div>
            <div>
                <button className='btnRegistrar' onClick={onSubmit}>Registrarme</button>
            </div>

        </div>

    )
};

export default Form; 