import React, { useState } from 'react';
import poster from './assets/CLAN_Banner.jpeg';
import './App.css';
import Form from './components/Form/form';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Consejo Latinoamericano de Alimentacion Natural
        </p>
        
      </header>
      <div className='Body'>
        <div className='banner'>
          <img src={poster} className="App-logo" alt="logo" />
        </div>
      </div>
      <div className='seccionForm'>
        <div className='sectionHead'>Inscripción</div>
        <Form/>

      </div>

      <div className='Footer'>
          <div style={{height:'40px'}}> Derechos reservados</div>
      </div>
    </div>
  );
}

export default App;
